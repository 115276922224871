import React from 'react'

import cssAlianzas from '../styles/alianzas.module.scss'
import Layout from '../components/layout'

import rig from '../assets/rig.png'
import maraure from '../assets/MARAURESP.jpg'
import lygar from '../assets/LYGAR.jpg'
import etech from '../assets/E&TECH.jpg'
import grey from '../assets/GREY.jpg'


const Alianzas = () => {
  return (
    <Layout>
      <div className={cssAlianzas.raiz}>
        <div className={cssAlianzas.banner}>
          <h1 className={cssAlianzas.tituloprincipal}>Sirviendo a la Industria Mexicana del Petróleo y Gas</h1>
        </div>
        {/*<div className={cssServicios.modelo}>
          <img src={modelo} className={cssServicios.foto}/>
        </div>*/}

        <section className={cssAlianzas.seccionuno}>
          <h2 className={cssAlianzas.titulo}>Servicios Mediante Alianzas</h2>
          <p className={cssAlianzas.parrafo}>Administración de Base de Datos/ digitalización de información de pozos. (Alianza con MEXADATA).</p>
          <p className={cssAlianzas.parrafo}>Servicio de Limpieza industrial, asesoría técnica en Control de Solidos. (Alianza con SP MARAURE).
          </p>
          <p className={cssAlianzas.parrafo}>Servicios de auditorias ambientales, limpiezas de tanques remoción de área afectada por contaminación. (Alianza con LYGAR).
          </p>
          <p className={cssAlianzas.parrafo}>Suministro de accesorios y equipos de perforación, transportación y trámites de importación. (Alianza con SINERGY BY ENERGY).
          </p>
          <p className={cssAlianzas.parrafo}>Servicios de supervisión y control de procesos petroleros. Suministro de sensores para monitoreo en tiempo real. (Alianza con E&TECH).
          </p>
          <p className={cssAlianzas.parrafo}>Gestión de activos e integridad, servicios de pruebas no destructivas, servicios de supervisión y control de calidad. (Alianza con GREY).
          </p>

        </section>

        <aside className={cssAlianzas.imagenes}>
          <img className={cssAlianzas.rig} src={rig} alt={""}/>
          <img className={cssAlianzas.maraure} src={maraure} alt={""}/>
          <img className={cssAlianzas.lygar} src={lygar} alt={""}/>
          <img className={cssAlianzas.etech} src={etech} alt={""}/>
          <img className={cssAlianzas.grey} src={grey} alt={""}/>
        </aside>

        {/*<div className={cssServicios.yacimiento}>
          <img src={pozo} className={cssServicios.foto}/>
        </div>*/}

      </div>
    </Layout>
  )
}

export default Alianzas;